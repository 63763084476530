export default Qualities => {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-12 sm:py-16 lg:px-8 lg:py-20">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-2xl font-bold tracking-tight text-title sm:text-4xl" data-aos="fade-right">
            MP Wealth Corp Group Consulting Ltd, your ally to bring your projects to fruition by finding the right partners you need.
          </h2>
        </div>
        <dl className="mt-10 text-center sm:mx-auto sm:grid sm:max-w-3xl sm:grid-cols-3 sm:gap-8">
          <div className="flex flex-col" data-aos="zoom-in">
            <dt className="order-2 mt-2 text-lg font-medium leading-6 text-orange-400">Reliable</dt>
            <dd className="order-1 text-5xl font-bold tracking-tight text-green-500">100%</dd>
          </div>
          <div className="mt-10 flex flex-col sm:mt-0" data-aos="zoom-in">
            <dt className="order-2 mt-2 text-lg font-medium leading-6 text-orange-400">Available <br/>customer service</dt>
            <dd className="order-1 text-5xl font-bold tracking-tight text-green-500">24/7</dd>
          </div>
          <div className="mt-10 flex flex-col sm:mt-0" data-aos="zoom-in">
            <dt className="order-2 mt-2 text-lg font-medium leading-6 text-orange-400">Years of expertise</dt>
            <dd className="order-1 text-5xl font-bold tracking-tight text-green-500">5+</dd>
          </div>
        </dl>
      </div>
    </div>
  )
}
