import Image from '../../../images/us.jpg'

export default function Example() {
  return (
    <div className="relative bg-white" id="about">
      <div className="mx-auto max-w-7xl lg:flex lg:justify-between lg:px-8 xl:justify-end">
        <div className="lg:flex lg:w-1/2 lg:shrink lg:grow-0 xl:absolute xl:inset-y-0 xl:right-1/2 xl:w-1/2">
          <div className="relative h-80 lg:-ml-8 lg:h-auto lg:w-full lg:grow xl:ml-0">
            <img
              className="absolute inset-0 h-full w-full bg-gray-50 object-cover"
              src={Image}
              alt=""
            />
          </div>
        </div>
        <div className="px-6 lg:contents">
          <div className="mx-auto max-w-2xl pb-24 pt-16 sm:pb-32 sm:pt-20 lg:ml-8 lg:mr-0 lg:w-full lg:max-w-lg lg:flex-none lg:pt-32 xl:w-1/2">
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-indigo-900 sm:text-4xl" data-aos="fade-down">About us</h1>
            <p className="mt-6 text-xl leading-8 text-gray-700" data-aos="fade-right">
              MP Wealth Corp Group Consulting Ltd is an international company based in London that provides corporate business advice and assistance with global projects.
            </p>
            <p className="mt-6 text-xl leading-8 text-gray-700" data-aos="fade-right">
              MP Wealth Corp Group Consulting Ltd also operates in the aviation service sector, currently active in numerous continents in Europe, Africa, Middle-East and is duly
              registered under the law of England and Wales in the British chamber of commerce and in the Company House.
            </p>
            <p className="mt-6 text-xl leading-8 text-gray-700" data-aos="fade-right">
              It was founded by Arnold-Valentin Kashama, who also serves as the manager, along with co-manager Benoit Thimister.
            </p>
            <p className="mt-6 text-xl leading-8 text-gray-700" data-aos="fade-right">
              Arnold-Valentin Kashama is a specialist in business management and in football industry.
            </p>
            <p className="mt-6 text-xl leading-8 text-gray-700" data-aos="fade-right">
              MP Wealth Corp Group Consulting Ltd assists private businesses and government entities at all levels in developing countries to achieve their 
              aims and goals.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
