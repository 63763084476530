import React, {useState }from 'react';
import Modal from './components/ModalContact'
import Hero from './components/Hero'
import Qualities from './components/Qualities'
import WhoAreWe from './components/WhoAreWe'
import Services from './components/Services'
import Procedures from './components/Procedures';
import References from './components/References'
import Footer from './components/Footer'
import Cta from './components/Cta'

import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

export default Home => {
  const [modal, setModal] = useState(false)
  const openModal = () => {
    setModal(true)
  }
  return(
    <React.Fragment>
      <Modal modal={modal} setModal={setModal}/>
      <Hero openModal={openModal}/>
      <Qualities/>
      <Cta text={""} openModal={openModal}/>
      <WhoAreWe/>
      <Cta text={""} openModal={openModal}/>
      <Services/>
      <References/>
      {/**<Procedures/>*/}
      <Footer/>
    </React.Fragment>
  )
}
// <Cta text={"Vous n'êtes pas encore client, avez-vous un projet?"} openModal={openModal}/>
// <Cta text={"partenaires trouvés, projet financé !"} openModal={openModal}/>