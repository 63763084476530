import Secure4u from '../../../images/cq5dam.web.680.9999.jpeg'

const navigation = {
  solutions: [
    // { name: `MP WEALTH CORP Sarl`, href: '#' },
    { name: `72-74, Rue du Canal,`, href: '#' },
    { name: `L-4051 Esch sur Alzette`, href: '#' },
    { name: `Luxembourg`, href: '#' },
    { name: 'Tel (LU): +352 69 17 21 684', href: 'tel:00352691721684' },

  ],
  solutions2: [
    // { name: `MP WEALTH CORP GROUP LTD`, href: '#' },
    { name: `85 Great Portland Street,`, href: '#' },
    { name: `W1W 7LT London`, href: '#' },
    { name: `United Kingdom`, href: '#' },
    { name: `REG : 15383547`, href: '#' },
    { name: 'Tel (UK): +44 7441 463899', href: 'tel:00447441463899' },

  ],
  support: [
    { name: 'Mon - Fri: 8h-20h', href: '#' },
    { name: 'Sat - Sun: Closed', href: '#' },
  ],
  company: [
    { name: 'email: info@mpwealthcorp.com', href: 'mailto:: info@mpwealthcorp.com' },
    { name: 'email: consulting@mpwealthcorp.com', href: 'mailto:: consulting@mpwealthcorp.com' },
  ],
  social: [
    // {
    //   name: 'Facebook',
    //   href: 'https://www.facebook.com/Infirmiers-des-voies-publiques-102065581196707/',
    //   icon: (props) => (
    //     <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
    //       <path
    //         fillRule="evenodd"
    //         d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
    //         clipRule="evenodd"
    //       />
    //     </svg>
    //   ),
    // },
  ],
}

export default function Example() {
  return (
    <footer className="bg-white" aria-labelledby="footer-heading" id="contact">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-1 xl:gap-8">
          <div className="grid grid-cols-1 sm:grid-col-1 md:grid-cols-2  lg:grid-cols-4 gap-8 xl:col-span-2">
            <div className="md:grid md:grid-cols-1 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-title tracking-wider uppercase">MP WEALTH CORP GROUP Consulting Ltd</h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.solutions2.map((item) => (
                    <li key={item.name}>
                      <span  className="text-base text-footer">
                        {item.name}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {/*<div className="md:grid md:grid-cols-1 md:gap-8 mt-16 sm:mt-0">*/}
            {/*  <div>*/}
            {/*    <h3 className="text-sm font-semibold text-title tracking-wider uppercase">MP WEALTH CORP GROUP Sarl</h3>*/}
            {/*    <ul role="list" className="mt-4 space-y-4">*/}
            {/*      {navigation.solutions.map((item) => (*/}
            {/*        <li key={item.name}>*/}
            {/*          <span  className="text-base text-footer">*/}
            {/*            {item.name}*/}
            {/*          </span>*/}
            {/*        </li>*/}
            {/*      ))}*/}
            {/*    </ul>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="md:grid md:grid-cols-1 md:gap-8 mt-16 sm:mt-0">
              <div>
                <h3 className="text-sm font-semibold text-title tracking-wider uppercase">OPENING HOURS</h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.support.map((item) => (
                    <li key={item.name}>
                      <div href={item.href} className="text-base text-footer whitespace-nowrap">
                        {item.name}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-1 md:gap-8 mt-16 sm:mt-0">
              <div>
                <h3 className="text-sm font-semibold text-title tracking-wider uppercase">CONTACT US</h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="whitespace-nowrap text-base text-footer">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-700 pt-8 flex-col md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-2">
            {navigation.social.map((item) => (
              <a key={item.name} href={item.href} className="text-little">
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
          <div className='mt-8 md:mt-0 md:order-1 md:flex md:items-center md:justify-between w-screen md:px-32'>
            <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
            </p>
            <img src={""} className='h-16 md:order-2 mt-8 md:mt-0' />
          </div>

        </div>
      </div>
    </footer>
  )
}
