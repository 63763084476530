import { useState, useRef, useEffect } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import Logo from '../../../images/logo.png'
import Hero_image from '../../../images/hero_image.jpg'

import Flag from '../../../images/france.png'

const navigation = [
  { name: 'Home', href: '/en/home' },
  { name: 'About us', href: '#about' },
  { name: 'Our services', href: '#services' },
  { name: 'Our references', href: '#references' },
  // { name: 'Procedures', href: '#process' },
  { name: 'Contact us', href: '#contact' },
]

export default function Example(props) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [menuColor, setMenuColor] = useState(true)
  const menu = useRef(0)
  const text = useRef(0)
  useEffect(() => {
    window.addEventListener("scroll", scrolling)
  },[])

  const scrolling = () => {
    const scrollingHeight = window.scrollY
    if(scrollingHeight < 50){
      menu.current.classList.remove("bg-white")
      setMenuColor(true)
    } else {
      menu.current.classList.add("bg-white")
      setMenuColor(false)
    }
  }
  return (
    <div className="bg-gray-900">
      <header className="fixed inset-x-0 top-0 z-50 w-screen transition-all drop-shadow-2xl" ref={menu}>
        <nav className="flex items-center justify-between p-6 lg:px-8 " aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="/en/home" className="-m-1.5 p-1.5">
              <span className="sr-only">Wealth Corp</span>
              <img
                className="h-16 w-auto"
                src={Logo}
                alt=""
              />
            </a>
          </div>
          <div className="flex justify-center lg:hidden">
            <button
              type="button"
              className={`-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 ${menuColor?  "text-white" : "text-title"} hover:text-footer`}
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              <div className={`mr-2 font-bold text-md ${menuColor?  "text-white" : "text-title"}`}>
                Menu
              </div>
            </button>
            <a href='/fr'>
              <img src={Flag} className='h-6'/>
            </a>
          </div>
          <div className="hidden lg:flex lg:gap-x-12 lg:justify-self-center">
            <a href='/fr'>
              <img src={Flag} className='h-8'/>
            </a>
            {navigation.map((item) => {
              return(
              <a key={item.name} href={item.href} className={`text-sm font-bold leading-6 ${menuColor?  "text-white" : "text-title"} hover:text-footer`} ref={text}>
                {item.name}
              </a>
              )
            })}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          </div>
        </nav>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
            <div className="flex items-center justify-between">
              <a href="/en" className="-m-1.5 p-1.5">
                <span className="sr-only">Wealth Corp</span>
                <img
                  className="h-8 w-auto"
                  src={Logo}
                  alt=""
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-400"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/25">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => {
                    return(
                    <a
                      key={item.name}
                      href={item.href}
                      onClick={() => setMobileMenuOpen(false)}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-800">
                      {item.name}
                    </a>
                    )
                  })}
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>

      <div className="relative isolate overflow-hidden pt-14">
        <img
          src={Hero_image}
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover"
        />
        <div
        src=""
        alt=""
        className="absolute inset-0 -z-10 h-full w-full object-cover bg-opacity-50  bg-indigo-500"></div>
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
          <div className=" text-left mx-6 lg:mx-12">
            <h1 className="font-bold tracking-tight text-white text-2xl sm:text-3xl md:text-4xl" data-aos="fade-down" data-aos-duration="1000">
              Consulting your projects,
            </h1>
            <h1 className="font-bold tracking-tight text-white text-4xl sm:text-5xl md:text-6xl mt-6" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="1000">
              Our mission!
            </h1>
            <p className="mt-6 text-lg sm:text-2xl leading-8 text-gray-100" data-aos="fade-up" data-aos-delay="2000" data-aos-duration="1000">
              For the development of the people, and solely for the people!
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="#"
                className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                data-aos="zoom-in" data-aos-delay="3000" data-aos-duration="1000"
                onClick={props.openModal}
              >
                Your project
              </a>
              <a href="#whoAreWe" className="text-sm font-semibold leading-6 text-white" data-aos="zoom-in" data-aos-delay="3000" data-aos-duration="1000">
                Learn more <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>
    </div>
  )
}
// MP Wealth Corp, votre allié pour concrétiser vos projets en trouvant les financements dont vous avez besoin.