import {useState, useEffect, useRef} from 'react'
import Procedure_image from '../../../images/procedure_image.jpeg'
import { CheckCircleIcon } from '@heroicons/react/20/solid'

import { CheckIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}



export default Procedures => {
  const [steps, changeSteps] = useState([
    { name: 'Step 1', status: 'current' },
    { name: 'Step 2', status: 'upcoming' },
    { name: 'Step 3', status: 'upcoming' },
  ])

  const currentDescription = [
    { step: "Step 1", text: "Submit the application along with a letter of interest and project teaser via email by the promoter." },
    { step: "Step 2", text: "Review of the application, data verification, and due diligence." },
    { step: "Step 3", text: "partner financing approval, sending the signed and stamped RWA (Ready, Willing, and Able), tracking the payment of processing fees, and then sending the Service Agreement by the partner." }
  ];
  const [description, changeDescription] = useState(currentDescription[0])
  useEffect(() => {
    const intervalId = updateSteps();
  
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  
  const updateSteps = () => {
    let newStep = 0;
    const newSteps = [
      { name: 'Step 1', status: 'current' },
      { name: 'Step 2', status: 'upcoming' },
      { name: 'Step 3', status: 'upcoming' },
    ]
    return setInterval(() => {
      newStep++;
      if (newStep > 2) {
        newStep = 0;
      }

      changeDescription(currentDescription[newStep]);

      for(let i = 0; i <= 2; i++){
        if(i == newStep){
          newSteps[i].status = "current"
        } 
        else if(i < newStep){
          newSteps[i].status = "complete"
        } else if(i > newStep){
          newSteps[i].status = "upcoming"
        }
      }
      changeSteps(newSteps)
    }, 3000);
  };
  return (
    <div className="relative bg-gray-100 px-6 py-24 sm:py-32 lg:px-8" id="process">
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-xl font-bold tracking-tight text-title sm:text-5xl">Procedures for Project Financing Applications</h2>
        <p className="mt-6 text-lg leading-8 text-gray-300 flex items-center text-green-500">
          80% of our clients request their financing via email.
        </p>
        <div className="mt-12 text-left text-2xl md:text-3xl leading-8 text-little flex items-center">Starting from 3.7% per year.</div>
        <div className="mt-6 text-lg leading-8 text-gray-300 flex items-center">
          <div>
            <CheckCircleIcon className='h-6 w-6 mr-4 text-green-500'/>
          </div>
          <p className='text-left text-title'>Subscribe to financing at a competitive rate.</p> 
        </div>
        <div className="mt-6 text-lg leading-8 text-gray-300 flex items-center">
          <div>
            <CheckCircleIcon className='h-6 w-6 mr-4 text-green-500'/> 
          </div>
          <p className='text-left text-title'>The processing fees range from 0.9% to 1.7% of the total financing amount.</p>
        </div>
      </div>
      <nav aria-label="Progress" className='flex justify-center mt-16'>
        <ol role="list" className="flex items-center">
          {steps.map((step, stepIdx) => (
            <li key={step.name} className={classNames(stepIdx !== steps.length - 1 ? 'pr-8 sm:pr-20' : '', 'relative')}>
              {step.status === 'complete' ? (
                <>
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="h-0.5 w-full bg-title" />
                  </div>
                  <div
                    className="relative flex h-8 w-8 items-center justify-center rounded-full bg-title hover:bg-indigo-900"
                  >
                    <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                    <span className="sr-only">{step.name}</span>
                  </div>
                </>
              ) : step.status === 'current' ? (
                <>
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="h-0.5 w-full bg-gray-200" />
                  </div>
                  <div
                    className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-title bg-white"
                    aria-current="step"
                  >
                    <span className="h-2.5 w-2.5 rounded-full bg-title" aria-hidden="true" />
                    <span className="sr-only">{step.name}</span>
                  </div>
                </>
              ) : (
                <>
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="h-0.5 w-full bg-gray-200" />
                  </div>
                  <div
                    className="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400"
                  >
                    <span
                      className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                      aria-hidden="true"
                    />
                    <span className="sr-only">{step.name}</span>
                  </div>
                </>
              )}
            </li>
          ))}
        </ol>
      </nav>
      <div className={`text-center mt-6 text-2xl text-gray-300  flex flex-col items-center h-72 md:h-36`}>
        <div className='text-4xl text-title'>{description.step}</div>
        <p className='max-w-xl mt-6 text-title'>{description.text}</p>
      </div>
    </div>
  )
}

