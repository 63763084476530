import Tanzania from '../../../images/references/Tanzanie.jpg'
import Vietnam from '../../../images/references/Vietnam.jpg'
import Iran from '../../../images/references/Iran.jpg'
import Nigeria from '../../../images/references/Nigeria.jpg'
import Factories from '../../../images/references/Factories.jpg'
import Bayern from '../../../images/references/Bayern.jpg'
import Turkey from '../../../images/references/Turkey.mp4'

const people = [
  {
    name: 'Tanzanie',
    role: 'Summary 30 MWp: first step 15, 12 MWp turn key solar project - Pemba Micheweni',
    imageUrl:Tanzania
  },
  // {
  //   name: 'Vietnam',
  //   role: `Distribution agreement with farms in Vietnam to market their various organic fruit varieties`,
  //   imageUrl: Vietnam
  // },
  // {
  //   name: 'Iran',
  //   role: `Distribution agreement with a sand mine in Iran for the sale of the entire available production of garnet sand`,
  //   imageUrl: Iran
  // },
  // {
  //   name: 'Nigeria',
  //   role: 'Distribution agreement with a farm in Nigeria',
  //   imageUrl: Nigeria
  // },
  // {
  //   name: 'Factories',
  //   role: `Distribution agreement with various factories in Vietnam to market their different types of plywood`,
  //   imageUrl: Factories
  // },
  {
    name: 'Bayern - Sonnefeld',
    role: `Rooftop (2 roofs), 2,383 kWp`,
    imageUrl: Bayern
  },
  {
    name: 'Güzelçamlı, turkey',
    role: `Construction Project`,
    imageUrl: Turkey
  },
]

export default function Example() {
  return (
    <div className="bg-white py-24 sm:py-32" id='references'>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="flex justify-center">
          <h2 className="text-3xl font-bold tracking-tight text-title sm:text-4xl text-center sm:text-center" data-aos="fade-down">Our references worldwide.</h2>
        </div>
        <ul
          role="list"
          className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3"
        >
          {people.map((person) => {
            const url = person.imageUrl.split(".")
            const extention = url[url.length - 1]
            console.log(extention)
            if(extention === "jpg"){
              return(
                <li key={person.name} className='pointer-events-none'>
                  <img className="aspect-[3/2] w-full rounded-2xl object-cover " src={person.imageUrl} alt="" data-aos="zoom-out"/>
                  <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900" data-aos="fade-right">{person.name}</h3>
                  <p className="text-base leading-7 text-gray-600" data-aos="fade-left">{person.role}</p>
                </li>
                )
            }
            else{
              return(
                <li key={person.name} className='pointer-events-none'>
                  <video muted autoPlay loop className="aspect-[3/2] w-full rounded-2xl object-cover ">
                    <source src={person.imageUrl} type={`video/${extention}`}/>
                  </video>
                  <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900" data-aos="fade-right">{person.name}</h3>
                  <p className="text-base leading-7 text-gray-600" data-aos="fade-left">{person.role}</p>
                </li>
                )
            }
            })}
        </ul>
      </div>
    </div>
  )
}
