import Background from '../../../images/procedure_image.jpeg'

export default function Example(props) {
  console.log(Background)
  return (
    <div className='relative'>
      <img
        src={Background}
        alt=""
        className="absolute inset-0 -z-10 h-full w-full object-cover object-right"
      />
      <div
      src=""
      alt=""
      className="absolute inset-0 -z-10 h-full w-full object-cover bg-opacity-50  bg-indigo-500"></div>
      <div className="" >
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 flex justify-center">
          <div className='flex flex-col items-center'>
            <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl text-left text-white text-center">
              {props.text}
            </h2>
            <div className="mt-10 flex justify-left gap-x-6 lg:mt-10 lg:flex-shrink-0">
              <div
                className="animate-bounce cursor-pointer rounded-md bg-green-600 px-3.5 py-2.5 text-lg md:text-xl font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={props.openModal}
              >
                Contact us!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
