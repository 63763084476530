import { CheckIcon } from '@heroicons/react/20/solid'

const features = [
  {
    name: 'Renewable Energy',
    description: `Building a green future by connecting with renewable energy visionaries.`,
  },
  { 
    name: 'Agriculture', 
    description: `By cultivating the future, we bring together innovative minds in agriculture.` 
  },
  {
    name: 'Tourism',
    description: `Lead the way to a flourishing future in tourism: connect travel visionaries with partners ready to explore new opportunities.`,
  },
  {
    name: 'Technology',
    description: `Pave the way for a promising technological future: unite technology sector innovators with partners ready to support progress.`,
  },
  {
    name:`Real estate`,
    description:`Choose us for unparalleled expertise, a personalized approach, total integrity, and an extensive network to address all your real estate needs.`
  },
  { 
    name: 'Infrastructure', 
    description: `Build a strong infrastructure future: bring infrastructure visionaries closer to partners ready to support growth.`
  },
  { 
    name: 'Transport', 
    description: `The transportation sector presents promising opportunities, where mobility innovation and sustainable solutions shape a smoother future.`
  },
  { 
    name: 'Humanitarian project', 
    description: `In the realm of humanitarian projects, compassionate initiatives and solidarity pave the way toward a more inclusive and supported world.`
  },
  // { 
  //   name: 'Gold and Diamond Mines', 
  //   description: `Dig into the future in the gold and diamond mining industry: connect mining sector experts with partners eager to be part of this adventure.`
  // },
  // {
  //   name: 'Donation',
  //   description: `Facilitate generosity and donations: unite donors with causes close to their hearts, creating a positive impact for a better world.`,
  // },
  // {
  //   name: 'Succession',
  //   description: `Facilitate succession management: guide heirs through a smooth and efficient process, carefully preserving family heritage.`,
  // },
  // {
  //   name: 'Credit Establishment or Loan Refinancing',
  //   description: `Simplify your finances: offer tailored credit and loan refinancing solutions to better manage your financial future.`,
  // },
  // {
  //   name: `Borrower's Guarantee`,
  //   description: `Secure your loan with our borrower's guarantee, for complete peace of mind during your financing.`,
  // },  
  // {
  //   name: 'Provision',
  //   description: `Protect your future: provide provision solutions for you and your loved ones, ensuring financial peace of mind in all circumstances.`,
  // },
  // {
  //   name: 'Collective or Individual Insurance',
  //   description: `Ensure your financial serenity: choose between collective or individual insurance for coverage tailored to your specific needs.`,
  // },
];

const features2 = [
  // {
  //   name: 'Home & Family Insurance',
  //   description: `Protect your home and loved ones: choose home & family insurance for total peace of mind in all circumstances.`,
  // },
  // {
  //   name: 'Legal Protection',
  //   description: `Preserve your rights and peace of mind: benefit from our legal protection to confidently handle all legal situations.`,
  // },
  // {
  //   name: 'Auto Insurance',
  //   description: `Safety on the road, peace of mind behind the wheel: choose our auto insurance for complete protection on all journeys.`,
  // },
  // {
  //   name: 'Capitalization Contract',
  //   description: `Grow your capital with serenity: discover our capitalization contract for intelligent investments and steady financial growth.`,
  // },
  // {
  //   name: 'Luxembourg Law Contract',
  //   description: `A strong contract, a secure future: opt for our Luxembourg law contract for reliable legal protection and confident investments.`,
  // },
  // {
  //   name: 'SBLC - Letter of Credit (purchase-lease deliveries)',
  //   description: `SBLC and Letter of Credit: Simplify your purchase, lease, and delivery transactions with financial solutions tailored to your needs.`,
  // },
  // {
  //   name: 'Securities Portfolio Management',
  //   description: `Optimize your investments with confidence: our securities portfolio management offers a tailored strategy for sustainable financial growth.`,
  // },
  // {
  //   name: 'Banking Services',
  //   description: `Facilitate your access to financial services: choose banking services for more efficient management of your personal and professional finances.`,
  // },
  // {
  //   name: 'Football Club Investment',
  //   description: `Invest in the future of football: join our sports investment club to support your passion and achieve lasting gains.`,
  // },
  {
    name: 'Football Club participation',
    description: `Change the game: explore the opportunity to participate in a football club and become a key player in the exciting world of sports.`,
  },
  {
    name: 'Entertainment',
    description: `Dive into the world of entertainment and explore a realm where innovation and creativity shape the future.`,
  },
  {
    name: 'Gold and diamond mining project',
    description: `Uncover the exceptional potential of gold and diamond mining projects, where natural wealth meets limitless opportunities.`,
  },
  {
    name: 'Industry',
    description: `Uncover the exceptional potential of the industry, where innovation and productivity create limitless opportunities.`,
  },
  {
    name: 'Telecoms',
    description: `Explore the vast potential of telecommunications, where advanced connectivity and technological innovation unlock a world of opportunities.`,
  },
  {
    name: 'Telecoms',
    description: `Explore the vast potential of telecommunications, where advanced connectivity and technological innovation unlock a world of opportunities.`,
  },
  {
    name: 'Health',
    description: `Discover the endless possibilities of the healthcare sector, where medical innovation and advanced care transform lives.`,
  },
  {
    name: 'Environment and Sustainable Development',
    description: `The environmental and sustainable development field holds promising prospects, where ecological commitment and sustainable solutions pave the way to a greener future.`,
  },
  { 
    name: 'Education', 
    description: `Education is a vital key to shaping tomorrow's leaders, driven by pedagogical innovation and equitable access to knowledge.`
  },
  // {
  //   name: 'Sports sponsoring',
  //   description: `Invest in the future of football: join our sports investment club to support your passion and achieve lasting gains.`,
  // },
];

export default function Example() {
  const toggleText = (e) => {
    const isHidden = e.target.parentElement.lastChild.classList.contains("hidden")
    if(isHidden){
      e.target.parentElement.lastChild.classList.remove("hidden")
    } else {
      e.target.parentElement.lastChild.classList.add("hidden")
    }

  }
  return (
    <div className="bg-white py-24 sm:py-32" id="services">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <div>
            <p className="mt-2 text-3xl font-bold tracking-tight text-title sm:text-4xl" data-aos="fade-down">OUR SERVICES</p>
            <p className="mt-6 text-base leading-7 text-title" data-aos="fade-right">
              We can ensure that any state has a very flexible approach to large infrastructure projects supported by their governments in the following areas.
            </p>
          </div>
          <div className="flex flex-col text-base leading-7 text-gray-600">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-9" >
                <div className="font-semibold text-title underline underline-offset-8 cursor-pointer" data-aos="fade-right" onClick={(e) => {toggleText(e)}}>
                  <CheckIcon className="absolute left-0 top-1 h-5 w-5 text-green-500" aria-hidden="true" />
                  {feature.name}
                </div>
                <div className="hidden mt-2 text-title">{feature.description}</div>
              </div>
            ))}
          </div>
          <div className="flex flex-col text-base leading-7 text-gray-600">
            {features2.map((feature) => (
              <div key={feature.name} className="relative pl-9" >
                <div className="font-semibold text-title underline underline-offset-8 cursor-pointer" data-aos="fade-right" onClick={(e) => {toggleText(e)}}>
                  <CheckIcon className="absolute left-0 top-1 h-5 w-5 text-green-500" aria-hidden="true" />
                  {feature.name}
                </div>
                <div className="hidden mt-2 text-title">{feature.description}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
