import { CheckIcon } from '@heroicons/react/20/solid'

const features = [
  {
    name: 'Énergie renouvelable',
    description: `Construire un avenir vert en vous connectant avec des visionnaires des énergies renouvelables.`,
  },
  { 
    name: 'Agriculture', 
    description: `En cultivant l’avenir, nous rassemblons les esprits innovants du secteur agricole.` 
  },
  {
    name: 'Tourisme',
    description: `Ouvrez la voie à un avenir florissant dans le tourisme : mettez en relation des visionnaires du voyage avec des partenaires prêts à explorer de nouvelles opportunités.`,
  },
  {
    name: 'Technologie',
    description: `Ouvrir la voie à un avenir technologique prometteur : unir les innovateurs du secteur technologique avec des partenaires prêts à soutenir le progrès.`,
  },
  {
    name:'Immobilier',
    description:`Choisissez-nous pour bénéficier d'une expertise inégalée, d'une approche personnalisée, d'une intégrité totale et d'un réseau étendu pour répondre à tous vos besoins immobiliers.`
  },
  { 
    name: 'Infrastructure', 
    description: `Construisons un avenir solide dans l'infrastructure : rapprochons les visionnaires de l'infrastructure des partenaires prêts à soutenir la croissance.`
  },
  { 
    name: 'Transport', 
    description: `Le secteur du transport offre des perspectives prometteuses, où l'innovation en mobilité et les solutions durables façonnent un avenir plus fluide.`
  },
  { 
    name: 'Projet humanitaire', 
    description: `Dans le domaine des projets humanitaires, les initiatives compassionnelles et les actions solidaires ouvrent la voie vers un monde plus inclusif et soutenu.`
  },
  // { 
  //   name: `Mines d'or et diamant`, 
  //   description: `Creusons l'avenir dans l'industrie des mines d'or et de diamants : mettons en relation les experts du secteur minier avec des partenaires désireux de participer à cette aventure.`
  // },
  // {
  //   name: 'Donation',
  //   description: `Facilitons la générosité et les dons : unissons les donateurs aux causes qui leur tiennent à cœur, créant ainsi un impact positif pour un monde meilleur.`,
  // },
  // {
  //   name: 'Succession',
  //   description: `Facilitons la gestion des successions : accompagnons les héritiers dans un processus fluide et efficace, préservant le patrimoine familial avec soin.`,
  // },
  // {
  //   name: 'Mise en place de crédits ou rachat de prêts',
  //   description: `Simplifions vos finances : offrons des solutions de crédit sur mesure et de rachat de prêts pour mieux gérer votre avenir financier.`,
  // },
  // {
  //   name: 'Garantie emprunteurs',
  //   description: `Sécurisez votre emprunt avec notre garantie emprunteurs, pour une tranquillité d'esprit totale lors de votre financement.`,
  // },  
  // {
  //   name: 'Prévoyance',
  //   description: `Protégeons votre avenir : offrons des solutions de prévoyance pour vous et vos proches, garantissant une tranquillité financière en toutes circonstances.`,
  // },
  // {
  //   name: 'Assurance collective ou individuelle',
  //   description: `Assurons votre sérénité financière : choisissez entre une assurance collective ou individuelle pour une couverture adaptée à vos besoins spécifiques.`,
  // },
]

const features2 = [

  // {
  //   name: 'Assurance habitation & famille',
  //   description: `Protégez votre chez-vous et ceux qui vous sont chers : optez pour une assurance habitation & famille pour une tranquillité totale en toutes circonstances.`,
  // },
  // {
  //   name: 'Protection Juridique',
  //   description: `Préservez vos droits et votre tranquillité d'esprit : bénéficiez de notre protection juridique pour faire face à toutes les situations juridiques avec confiance.`,
  // },
  // {
  //   name: 'Assurance automobile',
  //   description: `Sécurité sur la route, tranquillité d'esprit au volant : choisissez notre assurance automobile pour une protection complète sur tous les trajets.`,
  // },
  // {
  //   name: 'Contrat de capitalisation',
  //   description: `Faites fructifier votre capital en toute sérénité : découvrez notre contrat de capitalisation pour des investissements intelligents et une croissance financière constante.`,
  // },
  // {
  //   name: 'Contrat de droit Luxembourgeois',
  //   description: `Un contrat solide, un avenir sûr : optez pour notre contrat de droit luxembourgeois pour une protection juridique fiable et des investissements en toute confiance.`,
  // },
  // {
  //   name: 'SBLC - Lettre de crédit ( achat-location délivrances )',
  //   description: `SBLC et Lettre de Crédit : Facilitons vos transactions d'achat, de location et de délivrance avec des solutions financières adaptées à vos besoins.`,
  // },
  // {
  //   name: 'Gestion de portefeuille titres',
  //   description: `Optimisez vos investissements en toute confiance : notre gestion de portefeuille titres vous offre une stratégie sur mesure pour une croissance financière durable.`,
  // },
  // {
  //   name: 'Bancarisation',
  //   description: `Facilitons votre accès aux services financiers : choisissez la bancarisation pour une gestion plus efficace de vos finances personnelles et professionnelles.`,
  // },
  // {
  //   name: 'Investissement club de football',
  //   description: `Investissez dans l'avenir du football : rejoignez notre club d'investissement dédié au sport pour soutenir votre passion et réaliser des gains durables.`,
  // },
  {
    name: 'Sport',
    description: `Transformez le jeu : explorez l'opportunité de s'associer á un club de football et devenir un acteur clé dans le monde passionnant du sport.`,
  },
  {
    name: 'Divertissement ',
    description: `Plongez dans l'univers du divertissement et découvrez un monde où l'innovation et la créativité façonnent l'avenir.`,
  },
  {
    name: 'Projet d’exploitation d’or et diamantaire',
    description: `Découvrez le potentiel exceptionnel des projets d'exploitation d'or et de diamants, où la richesse naturelle rencontre des opportunités illimitées.`,
  },
  {
    name: 'Industrie',
    description: `Découvrez le potentiel exceptionnel de l'industrie, où innovation et productivité créent des opportunités illimitées.`,
  },
  {
    name: 'Télécoms',
    description: `Découvrez le potentiel exceptionnel de l'industrie, où innovation et productivité créent des opportunités illimitées.`,
  },
  {
    name: 'Santé ',
    description: `Découvrez les possibilités infinies du secteur de la santé, où l'innovation médicale et les soins avancés transforment des vies.`,
  },
  {
    name: 'Environnement et développement durable',
    description: `Le domaine de l'environnement et du développement durable offre des perspectives prometteuses, où l'engagement écologique et les solutions durables tracent la voie vers un avenir plus vert.`,
  },
  { 
    name: 'Education', 
    description: `L'éducation est une clé essentielle pour façonner les leaders de demain, grâce à l'innovation pédagogique et à un accès équitable à la connaissance.`
  },
  // {
  //   name: 'Sponsoring de sports',
  //   description: `Investissez dans l'avenir du football : rejoignez notre club d'investissement dédié au sport pour soutenir votre passion et réaliser des gains durables.`,
  // },
]

export default function Example() {
  const toggleText = (e) => {
    const isHidden = e.target.parentElement.lastChild.classList.contains("hidden")
    if(isHidden){
      e.target.parentElement.lastChild.classList.remove("hidden")
    } else {
      e.target.parentElement.lastChild.classList.add("hidden")
    }

  }
  return (
    <div className="bg-white py-24 sm:py-32" id="services">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <div>
            <p className="mt-2 text-3xl font-bold tracking-tight text-title sm:text-4xl" data-aos="fade-down">NOS SERVICES</p>
            <p className="mt-6 text-base leading-7 text-title" data-aos="fade-right">
              Nous pouvons faire en sorte que n'importe quel État dispose d'une enveloppe
              financière très souple et attrayante pour les grands investissements d'infrastructure
              soutenus par leurs gouvernements dans les domaines suivants.
            </p>
          </div>
          <div className="flex flex-col text-base leading-7 text-gray-600">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-9" >
                <div className="font-semibold text-title underline underline-offset-8 cursor-pointer" data-aos="fade-right" onClick={(e) => {toggleText(e)}}>
                  <CheckIcon className="absolute left-0 top-1 h-5 w-5 text-green-500" aria-hidden="true" />
                  {feature.name}
                </div>
                <div className="hidden mt-2 text-title">{feature.description}</div>
              </div>
            ))}
          </div>
          <div className="flex flex-col text-base leading-7 text-gray-600">
            {features2.map((feature) => (
              <div key={feature.name} className="relative pl-9" >
                <div className="font-semibold text-title underline underline-offset-8 cursor-pointer" data-aos="fade-right" onClick={(e) => {toggleText(e)}}>
                  <CheckIcon className="absolute left-0 top-1 h-5 w-5 text-green-500" aria-hidden="true" />
                  {feature.name}
                </div>
                <div className="hidden mt-2 text-title">{feature.description}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
